const triggerButton = document.querySelector('.trigger');
const formOverlay = document.querySelector('#form-overlay');
const triggerClose = document.querySelector('.trigger--close');

triggerButton.addEventListener(
	'click',
	function (event) {
		document.body.classList.toggle('form-open');
		formOverlay.classList.toggle('open');

		// Prevent default link behavior
		event.preventDefault();

		var expandedValue = triggerButton.getAttribute('aria-expanded');
		expandedValue = expandedValue == 'true' ? 'false' : 'true';
		triggerButton.setAttribute('aria-expanded', expandedValue);
		formOverlay.setAttribute('aria-expanded', expandedValue);
	},
	false
);

triggerClose.addEventListener(
	'click',
	function (event) {
		document.body.classList.toggle('form-open');
		formOverlay.classList.toggle('open');

		// Prevent default link behavior
		event.preventDefault();

		var expandedValue = triggerButton.getAttribute('aria-expanded');
		expandedValue = expandedValue == 'true' ? 'false' : 'true';
		triggerButton.setAttribute('aria-expanded', expandedValue);
		formOverlay.setAttribute('aria-expanded', expandedValue);
	},
	false
);

//bg updater

const root = document.documentElement;
const hr = new Date().getHours();

function updateBackground() {
	if (hr < 10) {
		root.style.setProperty('--fore', 'red');
	} else if (hr < 15) {
		root.style.setProperty('--fore', 'green');
	} else if (hr < 20) {
		root.style.setProperty('--fore', 'purple');
	} else {
		root.style.setProperty('--fore', 'black');
	}
}

setInterval(updateBackground, 1000 * 60);
updateBackground();

// Clock

// const deg = 6;
// const hour = document.querySelector('.hour');
// const min = document.querySelector('.min');
// const sec = document.querySelector('.sec');
// // const sec = document.querySelector('.open-sign');

// const setClock = () => {
// 	let day = new Date();
// 	let hh = day.getHours() * 30;
// 	let mm = day.getMinutes() * deg;
// 	let ss = day.getSeconds() * deg;

// 	hour.style.transform = `rotateZ(${hh + mm / 12}deg)`;
// 	min.style.transform = `rotateZ(${mm}deg)`;
// 	sec.style.transform = `rotateZ(${ss}deg)`;
// 	// clock.style.transform = `rotateZ(${ss}deg)`;
// };

// // first time
// setClock();

// // Update every 1000 ms
// setInterval(setClock, 1000);
